body.active-modal {
    overflow-y: hidden;
}

.modal, .modal-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;

    .modal-overlay {
        background: rgba(49,49,49,0.8);
    }

    .modal-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #f1f1f1;
        padding: 14px 28px;
        border-radius: 3px;
        max-width: 600px;
        min-width: 300px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        background-color: $twilight-blue;

        .cancel-btn, .confirm-btn {
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            background-clip: padding-box;
            width: 150px;
            height: 60px;
            cursor: pointer;

            @media (max-width: 448px) {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .cancel-btn {
            background-image: url('../images/cancel_graphic.svg');
            margin-right: 15px;

            @media (max-width: 448px) {
                margin-right: auto;
                margin-bottom: 5px;
            }

            &:hover, &:focus {
                background-image: url('../images/cancel_graphic_rollover.svg');
                outline: none;
            }
        }

        .confirm-btn {
            background-image: url('../images/confirm_graphic.svg');

            &:hover, &:focus {
                background-image: url('../images/confirm_graphic_rollover.svg');
                outline: none;
            }
        }
    }
}