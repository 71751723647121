.insert-users-background {
    background-image: url('../images/header-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

form {
    display: inline-flex;
    padding-bottom: 50px;

    @media (max-width: 850px) {
        display: flex;
    }

    @media (max-width: 720px) {
        display: inline-flex;
        flex-direction: column;
    }

    input, select {
        margin-right: 10px;
        background-color: $emerald;
        background-clip: padding-box;
        border: 4px solid transparent;
    
        &:disabled {
            background-color: $alto;        
        }
    
        &:hover:enabled, &:focus:enabled {
            box-shadow: 0 0 0 2px $amazon;
            border-radius: 10px;
            outline: none;
        }
    }
    
    input {
        font-family: $source-code-pro;
        color: $chathams-blue;
        text-align: center;
        font-size: 23px;
        height: 50px;
        width: 181px;
    
        @media (max-width: 720px) {
            width: fit-content;
        }
    }
    
    select {
        height: 60px;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $chathams-blue;
        opacity: 1; /* Firefox */
        font-size: 23px;
        font-family: $source-code-pro;
        text-align: center;
    }
        
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $chathams-blue;
        font-size: 23px;
        font-family: $source-code-pro;
        text-align: center;
    }
        
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $chathams-blue;
        font-size: 23px;
        font-family: $source-code-pro;
        text-align: center;
    }
    
    select, option {
        color: $chathams-blue;
        font-size: 23px;
        font-family: $source-code-pro;
        font-weight: 600;
        cursor: pointer;
        width: 181px;
        text-align: center;

        @media (max-width: 480px) {
            width: auto;
        }
    }
}



.logo {
    width: auto;
    height: 100px;
    padding-top: 20px;
}

.check-in, .divider, .check-out {
    font-family: $source-code-pro;
    font-size: 40px;
    font-weight: 800;
}

.check-in {
    color: $amazon;
}

.divider {
    color: $emerald;
}

.divider:after {
    @media (max-width: 448px) {
        content:"\a";
        white-space: pre;
    }
}

.check-out {
    color: $boston-blue;
}

.parent-check-in-btn {
    background: transparent;
    border: 0px;
    padding: 0;
    outline: 0; 
}

.check-in-btn {
    border-radius: 5px 5px 5px 0px;
    background-image: url('../images/in_graphic_v2.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-clip: padding-box;
    border: 3px solid transparent;
    width: 50px;
    height: 50px;
    cursor: pointer;

    &:hover, &:focus {
        background-image: url('../images/in_graphic_rollover_v2.svg');
        box-shadow: 0 0 0 2px $amazon;
        border-radius: 10px;
        outline: none;
    }
}
