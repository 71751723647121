@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@800&family=Roboto:wght@100&family=Source+Code+Pro:wght@600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@800&family=Roboto:wght@100&family=Source+Code+Pro:ital,wght@0,600;1,800&display=swap');

// Font Variables
$source-code-pro: 'Source Code Pro', monospace; 
$inconsolata: 'Inconsolata', monospace;

// Colors
$emerald: #57C99A;
$alto: #dbdbdb;
$amazon: #367D60;
$chathams-blue: #1A486B;
$boston-blue: #3AA0B0;
$twilight-blue: #ecfcff;
$boulder: #787878;
$hillary: #aba67d;
$dove-gray: #686868;

// Toastify variables
:root {
    --toastify-color-success: #367D60 !important;
    --toastify-color-info: #3AA0B0 !important;
}

