.data-container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 10px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 425px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .data-item {    
        margin: 10px;
        height: fit-content;
        background-color: $twilight-blue;
        border-radius: 5px;
        border: 1px solid $boston-blue;
        box-shadow: 4px 5px $boston-blue;

        .data-item-heading-parent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 0px 30px;

            .data-item-heading {
                color: $boulder;
                font-family: $inconsolata;
                font-size: 33px;
            }

            .data-item-chevron-close {
                width: 15px;
                transform: rotate(180deg);
                transition: transform 0.2s ease-in-out;
            }
        
            .data-item-chevron-open {
                width: 15px;
                transform: rotate(270deg);
                transition: transform 0.2s ease-in-out;
            }
        }

        .data-item-table-close {
            display: none;
            transition:  
            opacity 0.3s ease-in-out,
            height 0.3s 0.3s ease-in-out;
        }
    
        .data-item-table-open {
            table-layout: fixed;
            opacity: 1;
            transition:  
            height 0.3s ease-in-out,
            opacity 0.3s 0.3s ease-in-out;
            width: 100%;

            .data-item-table-titles {
                padding-bottom: 20px;
                color: $hillary;
                font-size: 28px;
                font-family: $inconsolata;            
            }

            .data-item-table-contents {
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                color: $dove-gray;
    
                .data-item-check-out-btn {
                    background-image: url('../images/out_graphic_v2.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    display: inline-block;
                    background-clip: padding-box;
                    border: 3px solid transparent;
                    width: 35px;
                    height: 35px;
                    cursor: pointer;
    
                    &:hover, &:focus {
                        background-image: url('../images/out_graphic_rollover_v2.svg');
                        box-shadow: 0 0 0 2px $boston-blue;
                        border-radius: 10px;
                        outline: none;
                    }
                }
            }

            .data-item-table-line {
                max-width: 90%;
                background: $hillary;
                height: 1px;
                border: 0px;
            }
        }
    }
}
